import React from "react";
import Header from "../components/Header";
import Img from "../images/comingsoon.jpg";
const ComingSoon = () => {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10">
          <img src={Img} className="w-100" alt="img"/>
          </div>
        </div>
      </div>
    </>
  );
};
export default ComingSoon;

import React from "react";
import ReactDOM from "react-dom/client";
import { SnackbarProvider } from "notistack";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-overlay-loader/styles.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <SnackbarProvider maxSnack={3}>
    <App />
  </SnackbarProvider>
  // </React.StrictMode>
);
reportWebVitals();

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/Login";
import ComingSoon from "./pages/Comingsoon";
import Home from "./pages/Home";
import Popcard from "./pages/Popcard";

const App = () => {
  return (
    <>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
          </Routes>
          <Routes>
            <Route path="/popcard/:id" element={<Popcard />} />
          </Routes>

          {/* auth */}
          <Routes>
            <Route exact path="/signup" element={<Signup />} />
          </Routes>
          <Routes>
            <Route exact path="/signin" element={<Login />} />
          </Routes>
          <Routes>
            <Route exact path="/guarantee" element={<ComingSoon />} />
          </Routes>
        </Router>
    </>
  );
};

export default App;

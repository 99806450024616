import { React, useState } from "react";
// import Logo from "../../images/Pop Authentication Services No Stroke.png";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { BaseUrl } from "../../helper/Baseurl";
import { Loader } from "react-overlay-loader";
import { useSnackbar } from "notistack";
import { useNavigate, Link } from "react-router-dom";
import bg from "../../images/loginback.png"
const Login = () => {
  const Navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please Enter a Valid Email")
      .required("Email Is Required"),
    password: Yup.string().required("Password is required"),
  });
  return (
    <>
      {loader ? <Loader fullPage loading /> : null}
      <div style={{ backgroundImage: `url(${bg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", minHeight: "100vh", backgroundPosition: "80%" }}>
        {/* <div
        className="container-fluid mt-2"
        style={{ borderBottom: "1px solid #f4f4f4" }}
      >
        <div className="row">
          <div className="col-md-12 mt-4 mb-1 d-flex justify-content-center">
            <img
              src={Logo}
              className="w-25"
              alt="POP AUTHENTICATION SERVICES"
            />
          </div>
        </div>
      </div> */}
        <div className="container-fluid">
          <div className="row mt-3 d-flex justify-content-start align-items-center" style={{ height: "100vh" }} >
            {/* <div className="col-md-4"></div> */}
            <div className="col-md-4 offset-1">
              <p className="create mt-3">Login</p>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={SignupSchema}
                onSubmit={(values) => {
                  setLoader(true);
                  console.log(values);
                  axios
                    .post(`${BaseUrl.baseUrl}login`, values)
                    .then((res) => {
                      console.log(res);
                      if (res.data.status === true) {
                        localStorage.setItem("Popweb", res?.data?.token);
                        localStorage.setItem(
                          "Popwebuser",
                          JSON.stringify(res?.data?.user)
                        );
                        setLoader(false);
                        Navigate("/");
                        enqueueSnackbar(res?.data?.message, {
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "success",
                          autoHideDuration: 2000,
                        });
                      } else {
                        setLoader(false);
                        enqueueSnackbar(res?.data?.message, {
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "error",
                          autoHideDuration: 2000,
                        });
                      }
                    })
                    // same shape as initial values
                    .catch((err) => {
                      setLoader(false);
                      console.log(err);
                      enqueueSnackbar(err?.response.data?.message, {
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "error",
                        autoHideDuration: 2000,
                      });
                    });
                }}
              >
                {({ errors, touched }) => (
                  <Form className="row">
                    <div className="form-group col-md-12">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-12">
                      <Field
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-end">
                      <p className="create2">
                        Don't Have Account?{" "}
                        <Link
                          to="/signup"
                          className=""
                          style={{ textDecoration: "none" }}
                        >
                          Sign Up
                        </Link>
                      </p>
                    </div>
                    <div className="d-flex justify-content-center mb-2 mt-2">
                      <button type="submit" className="btn btn-primary col-md-6">
                        Login
                      </button>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <p className="create2">
                        Copyright 2023 PopCard | Design By{" "}
                        <Link
                          to="https://hnhtechsolutions"
                          className=""
                          style={{ textDecoration: "none" }}
                        >
                          Hnh Tech Solutions
                        </Link>
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            {/* <div className="col-md-4"></div> */}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
export default Login;

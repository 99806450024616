import { React, useState } from "react";
import { Link } from "react-router-dom";
// import Logo from "../images/Pop Authentication Services No Stroke.png";
import Logo from "../images/newimages/2/logo.png";

import { BiLogIn } from "react-icons/bi";

const Header = () => {

  const [toggle, setToggle] = useState(false);
  const token = localStorage.getItem("Popweb")

  const setState1 = () => {
    if (toggle === true) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  const logout = () => {
    localStorage.clear()
  }
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={Logo}
              className="w-100"
              alt="POP AUTHENTICATION SERVICES"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setState1()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id={toggle === true ? "collapse" : null}
          // id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/guarantee">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/guarantee">
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/guarantee">
                  Guarantee
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/guarantee">
                  Contact Us
                </Link>
              </li>

              {token == null ?
                <li className="nav-item">
                  <Link className="nav-link" to="/signin">
                    Login <BiLogIn />
                  </Link>
                </li> :
                <li className="nav-item">
                  <Link className="nav-link" to="/signin" onClick={logout}>
                    Log out
                  </Link>
                </li>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;

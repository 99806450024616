import Logo from "../../images/Design3/logo.png";
import Img1 from "../../images/Design3/PopCard.png";
import Img2 from "../../images/Design3/barcode.png";
import { React, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../helper/Baseurl";
import { Loader } from "react-overlay-loader";
import { useSnackbar } from "notistack";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay } from "swiper";
import { styled } from "styled-components";
import { useMediaQuery } from "@mui/material";

const Design4 = () => {
  const { id } = useParams();
  const matches = useMediaQuery("(max-width:992px)");
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(true);
  const [Screen, setScreen] = useState(false);
  const [Data, setData] = useState([]);

  const search = () => {
    axios
      .get(`${BaseUrl.baseUrl}search/${id}`)
      .then((res) => {
        setData(res.data.result);
        setInterval(setScreen(true), 2000);
        setScreen(true);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        enqueueSnackbar(err?.data?.message, {
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // 1080 / 1440
  return (
    <>
      {" "}
      {loader ? <Loader fullPage loading /> : null}
      {Screen ? (
        <MainContainer>
          <div className={matches ? "container-fluid" : "container"}>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between align-items-center">
                <img src={Logo} style={{ width: "28%" }} alt="logo" />
                <Link to="/">
                  <BackButton>BACK TO MAIN MENU</BackButton>
                </Link>
              </div>
            </div>
            <Frame>
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 col-10">
                  <div className="row">
                    <div className="col-md-4">
                      <ImageCard $borderColor={Data.popPictureBordorColor}>
                        <Swiper
                          centeredSlides={true}
                          speed={900}
                          autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                          }}
                          modules={[Autoplay]}
                          className="mySwiper"
                        >
                          {Data?.poppicture &&
                            Data?.poppicture.map((e, i) => (
                              <SwiperSlide
                                style={{ borderRadius: "28px" }}
                                key={i}
                              >
                                {" "}
                                {e && (
                                  <img
                                    src={e}
                                    className="w-100"
                                    style={{ borderRadius: "28px" }}
                                    alt="img"
                                  />
                                )}
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </ImageCard>
                    </div>
                    <div className="col-md-4">
                      <div className="col-md-12  d-flex justify-content-center">
                        <img src={Img1} style={{ width: "115%" }} alt="img" />
                      </div>
                      <div className="col-md-12 d-flex justify-content-center border-color2">
                        <div className="row">
                          <div className="col-md-12 position-relative">
                            <img src={Img2} className="img-fluid" alt="img" />
                            <StickerNumber>{Data?.stickerNumber}</StickerNumber>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end">
                      <ImageCard $borderColor={Data.popSignerBordorColor}>
                        <Swiper
                          centeredSlides={true}
                          speed={900}
                          autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                          }}
                          modules={[Autoplay]}
                          className="mySwiper"
                        >
                          {Data?.popSigner &&
                            Data?.popSigner.map((e, i) => (
                              <SwiperSlide
                                style={{ borderRadius: "28px" }}
                                key={i}
                              >
                                {" "}
                                {e?.pop_signer && (
                                  <img
                                    src={e?.pop_signer}
                                    className="w-100"
                                    style={{ borderRadius: "28px" }}
                                    alt="img"
                                  />
                                )}
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </ImageCard>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 col-10">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <ContentCard>
                        <div className="cardHeader">Funko Pop Details</div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">Funko Pop Series</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center">
                          {Data.funkoPopSeries ? (
                            <p className="values">{Data.funkoPopSeries}</p>
                          ) : (
                            <p className="values">&nbsp;</p>
                          )}
                        </div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">Funko Pop Number</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center">
                          {Data.funkoPopNumber ? (
                            <p className="values">{Data.funkoPopNumber}</p>
                          ) : (
                            <p className="values">&nbsp;</p>
                          )}
                        </div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">Funko Pop Name</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center">
                          {Data.funkoPopName ? (
                            <p className="values">{Data.funkoPopName}</p>
                          ) : (
                            <p className="values">&nbsp;</p>
                          )}
                        </div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">UPC Number</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center">
                          {Data.upcNumber ? (
                            <p className="values">{Data.upcNumber}</p>
                          ) : (
                            <p className="values">&nbsp;</p>
                          )}
                        </div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">Box Date Code</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center">
                          {Data.boxDateCode ? (
                            <p className="values">{Data.boxDateCode}</p>
                          ) : (
                            <p className="values">&nbsp;</p>
                          )}
                        </div>
                      </ContentCard>
                    </div>
                    <div className="col-md-4">
                      <ContentCard>
                        <div className="cardHeader">Authentication Details</div>
                        <div className="row justify-content-center">
                          <div className="col-md-6">
                            <div className="col-md-12 mt-3">
                              <p className="heading">status</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                              {Data.status ? (
                                <p className="values">{Data.status}</p>
                              ) : (
                                <p className="values">&nbsp;</p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <p className="heading">Location Signed</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                              {Data.locationSigned ? (
                                <p className="values">{Data.locationSigned}</p>
                              ) : (
                                <p className="values">&nbsp;</p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <p className="heading">Signed Affidavit</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                              {Data.signAffidavit ? (
                                <p className="values">{Data.signAffidavit}</p>
                              ) : (
                                <p className="values">&nbsp;</p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <p className="heading">On Site Witness</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                              {Data.authenticationWitness ? (
                                <p className="values">
                                  {Data.authenticationWitness.witness}
                                </p>
                              ) : (
                                <p className="values">&nbsp;</p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="col-md-12 mt-3">
                              <p className="heading">Service</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                              {Data.authenticationService ? (
                                <p className="values">
                                  {Data.authenticationService}
                                </p>
                              ) : (
                                <p className="values">&nbsp;</p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <p className="heading">Date Signed</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                              {Data.dateSigned ? (
                                <p className="values">{Data.dateSigned}</p>
                              ) : (
                                <p className="values">&nbsp;</p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <p className="heading">video/Clip</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                              {Data.videoOrClip ? (
                                <a
                                  className="values"
                                  href={Data.videoOrClip}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  CLICK HERE
                                </a>
                              ) : (
                                <p className="values">N/A</p>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <p className="heading">Gps MetaData</p>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center">
                              <p className="values">
                                {Data.gpsMetaData || "N/A"}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="row justify-content-center">
                              <div
                                className="col-md-10 d-flex flex-column justify-content-center"
                                style={{ position: "absolute", bottom: 10 }}
                              >
                                <p className="heading">
                                  Qualifies for Our $1,000,000 Guarantee
                                </p>
                                <Link to={"/guarantee"} className="values">
                                  CLICK HERE
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ContentCard>
                    </div>
                    <div className="col-md-4">
                      <ContentCard>
                        <div className="cardHeader">Signature Details</div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">Signature Type</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center">
                          {Data.signatureType ? (
                            <p className="values">{Data.signatureType}</p>
                          ) : (
                            <p className="values">&nbsp;</p>
                          )}
                        </div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">Signed by</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center">
                          {Data.signer ? (
                            <p className="values">
                              {Data.signer.map(
                                (e, i, arr) =>
                                  `${arr.length - 1 !== i ? e + ", " : e}`
                              )}
                            </p>
                          ) : (
                            <p className="values">&nbsp;</p>
                          )}
                        </div>
                      </ContentCard>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <ContentCard>
                        <div className="cardHeader">Pop Fax</div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">Registered Owner</p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center">
                          <p className="values">N/A</p>
                        </div>
                        <div className="row justify-content-around">
                          <div className="col-md-6 mt-3">
                            <p className="heading">Purchase Date</p>
                          </div>
                          <div className="col-md-6 mt-3">
                            <p className="heading">Purchase Price</p>
                          </div>
                          <div
                            className="col-md-4 mt-3"
                            style={{ paddingInline: "8px" }}
                          >
                            <p className="values_box2">N/A</p>
                          </div>
                          <div
                            className="col-md-4 mt-3"
                            style={{ paddingInline: "8px" }}
                          >
                            <p className="values_box2">N/A</p>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">Previous Ownership History</p>
                        </div>
                        <div className="row" style={{ paddingLeft: "3px" }}>
                          <div className="col-md-4">
                            <p className="heading">Name</p>
                          </div>
                          <div className="col-md-4">
                            <p className="heading">Date</p>
                          </div>
                          <div className="col-md-4">
                            <p className="heading">Amount</p>
                          </div>
                          <div className="col-md-4">
                            <p className="values">N/A</p>
                          </div>
                          <div className="col-md-4">
                            <p className="values">N/A</p>
                          </div>
                          <div className="col-md-4">
                            <p className="values">N/A</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 d-flex justify-content-center">
                            <div
                              style={{
                                position: "absolute",
                                bottom: 10,
                                width: "100%",
                                paddingInline: "1rem",
                              }}
                            >
                              <button className="transferButton w-100">
                                Submit Or Transfer Ownership
                              </button>
                            </div>
                          </div>
                        </div>
                      </ContentCard>
                    </div>
                    <div className="col-md-4">
                      <ContentCard>
                        <div className="cardHeader">Enhanced Details</div>
                        <div className="row justify-content-center mt-3">
                          <div className="col-md-6">
                            <p className="heading text-start">Encapsulated</p>
                          </div>
                          <div className="col-md-3">
                            <p className="value_box">
                              {Data.encapsulated || "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3">
                          <div className="col-md-6">
                            <p className="heading text-start">Graded</p>
                          </div>
                          <div className="col-md-3">
                            <p className="value_box">{Data.graded || "N/A"}</p>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-3">
                          <div className="col-md-6">
                            <p className="heading text-start">Grade Number</p>
                          </div>
                          <div className="col-md-3">
                            <p className="value_box">
                              {Data.gradeNumber || "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">Grading Company</p>
                        </div>
                        <div className="col-md-12">
                          <p className="values">
                            {Data.gradingCompany || "N/A"}
                          </p>
                        </div>
                        <div className="col-md-12 mt-3">
                          <p className="heading">Grading Report Link</p>
                        </div>
                        <div className="col-md-12">
                          <p className="values">
                            {Data.gradingReportLink ? (
                              <a
                                className="values"
                                href={
                                  Data.gradingReportLink.includes("https://")
                                    ? Data.gradingReportLink
                                    : "https://" + Data.gradingReportLink
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                CLICK HERE
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </p>
                        </div>
                      </ContentCard>
                    </div>
                    <div className="col-md-4">
                      <ContentCard>
                        <div className="cardHeader">Notes</div>
                        <div className="row justify-content-center">
                          <div className="col-md-10 mt-3 p-0">
                            <p className="noteBox">{Data.note}</p>
                          </div>
                          <div
                            className="col-md-10 text-center mt-3"
                            style={{ position: "absolute", bottom: 10 }}
                          >
                            <img
                              src={Logo}
                              alt="logo"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                      </ContentCard>
                    </div>
                  </div>
                </div>
              </div>
            </Frame>
            <div className="row mt-3">
              <div className="col-md-12 text-center">
                <img src={"/img/bottom_logo.png"} width={300} alt="img" />
              </div>
            </div>
          </div>
        </MainContainer>
      ) : null}
    </>
  );
};

const MainContainer = styled.div`
  background-image: url(../img/background.jpeg);
  background-size: 100% 100%;
  padding-block: 2rem;
`;

const Frame = styled.div`
  background-image: url(../img/popcoa_frame.png);
  background-size: 100% 100%;
  padding-block: 3rem;
  margin-top: 1rem;
`;

const ImageCard = styled.div`
  width: 100%;
  min-height: 300px;
  border-radius: 30px;
  border: 3px solid;
  border-color: ${(props) =>
    props.$borderColor ? props.$borderColor : "#000"};
  background-color: #ffffff;

  @media (min-width: 1440px) {
    min-height: 350px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    min-height: auto;
  }
`;

const BackButton = styled.button`
  background-color: #db1e90 !important;
  color: #ffea4c;
  font-size: 15px !important;
  border-radius: 10px;
  font-family: Gotham-Bold;
  letter-spacing: 0.1px;
  padding: 12px;
  border: none;
`;

const ContentCard = styled.div`
  background-size: cover;
  // height: 100%;
  min-height: 410px;
  position: relative;
  border: solid 2px transparent; /* !importanté */
  border-radius: 1em;
  background: linear-gradient(#000, #000) padding-box,
    linear-gradient(to bottom, #2e77ce, #e4148f) border-box;
  border-radius: 1rem;
  border: 4px solid transparent;

  .cardHeader {
    font-family: "Bebas Kai";
    font-size: 18px;
    // font-style: italic;
    // font-weight: 600;
    background-color: #2e77ce;
    color: #fff;
    text-align: center;
  }

  .heading {
    text-decoration: underline;
    font-family: "Bebas Kai";
    font-size: 14px;
    // font-weight: 600;
    color: #f0f0f0;
    letter-spacing: 1px;
    text-align: center;
    margin: 0;
  }

  .values {
    font-family: "Bebas Kai";
    // color: #FEEB08;
    color: #c8cb0c;
    text-align: center;
    margin: 0;
  }

  .value_box {
    font-family: "Bebas Kai";
    // background-color: #FEEB08;
    background-color: #c8cb0c;
    color: #000;
    text-align: center;
  }

  .values_box2 {
    font-family: "Bebas Kai";
    background-color: #b61774;
    color: #c8cb0c;
    text-align: center;
  }

  .noteBox {
    background-color: #fff;
    color: #000;
    min-height: 220px;
    border-radius: 1rem;
    font-family: "Bebas Kai";
    font-size: 14px;
    text-align: center;
    padding: 0.3rem;
  }

  .transferButton {
    text-transform: uppercase;
    font-family: "Bebas Kai";
    // background-color: #FEEB08;
    background-color: #c8cb0c;
    color: #000;
    text-align: center;
    outline: none;
    border: none;
    border-radius: 1rem;
  }

  @media only screen and (min-device-width: 1400px) {
    min-height: 380px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    min-height: 520px;
  }
  
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
    min-height: 415px;
  }
`;
const StickerNumber = styled.span`
  position: absolute;
  right: 17.5%;
  top: 63%;
  font-family: Gotham Black;
  text-transform: uppercase;
  background: linear-gradient(
    to right,
    #f5bdfc,
    #f8f8bf,
    #dafec4,
    #bcffee,
    #e5bcfc
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    right: 12.5%;
    top: 60%;
  }
`;
export default Design4;

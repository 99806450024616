import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { Loader } from "react-overlay-loader";
import { Link, useNavigate } from "react-router-dom";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Header from "../components/Header";
import Img1 from "../images/carousel/1.png";
import Img2 from "../images/carousel/2.png";
import Img3 from "../images/carousel/3.png";
import Img4 from "../images/newimages/2/Untitled-7.png";
import Img5 from "../images/newimages/2/val.png";
import { BaseUrl } from "../helper/Baseurl";

const Home2 = () => {
  const [searchfield, setsearchfield] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const Navigate = useNavigate();
  const search = () => {
    setLoader(true);
    if (searchfield === "") { 
      setLoader(false);
      enqueueSnackbar("Pop Card Number Is Required For Search", {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        variant: "error",
        autoHideDuration: 2000,
      });
    } else {
      axios.get(`${BaseUrl.baseUrl}search/${searchfield}`)
        .then((res) => {
          if (res.data.status === true) {
            setLoader(false);
            // Navigate(`/design3/${searchfield}`);
            Navigate(`/popcard/${searchfield}`);
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            setLoader(false);
            enqueueSnackbar(res?.data?.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          enqueueSnackbar(err?.message, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
  };
  return (
    <>
      {loader ? <Loader fullPage loading /> : null}

      <div className="container-fluid bg-img">
        <Header />
        <div className="container-fluid bg-blue">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 mt-1">
              <h2 className="verify text-center d-flex justify-content-center">
                VERIFY AUTHENTICITY
              </h2>
            </div>
          </div>
          <div className="row justify-content-center laptop-carousel">
            <div className="col-md-4 d-flex justify-content-end">
              <img src={Img4} alt="..." style={{ height: "52px" }} />
            </div>
            <div className="col-md-3 d-flex justify-content-start">
              <InputMask
                placeholder="- ENTER PASS #"
                className="enterpass"
                mask="a-999999"
                value={searchfield}
                onChange={(e) => {
                  setsearchfield(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setLoader(true);
                    search();
                  }
                }}
              ></InputMask>
            </div>
            <div className="col-md-4 d-flex justify-content-start">
              <Link to={'#'} onClick={search}>
                <img
                  src={Img5}
                  alt="..."
                  style={{
                    height: "60px",
                    marginTop: "-4px",
                    marginLeft: "-7px",
                  }}
                />
              </Link>
            </div>
          </div>
          <div className="row mobile-carousel">
            <div className="col-md-12 d-flex justify-content-center">
              <InputMask
                placeholder="- ENTER PASS #"
                className="enterpass2"
                mask="a-999999"
                value={searchfield}
                onChange={(e) => {
                  setsearchfield(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setLoader(true);
                    search();
                  }
                }}
              ></InputMask>
              <Link to={'#'} onClick={search}>
                <img src={Img5} alt="..." className="w-100" />
              </Link>
            </div>
          </div>
          <br />
        </div>
        <div className="container-fluid">
          <br />
          <br />
        </div>
        <div className="container-fluid">
          <div className="row mt-2 mb-2">
            <Swiper
              slidesPerView={1}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Autoplay]}
            >
              <SwiperSlide>
                <img src={Img1} className="w-100" alt="carousel 1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Img2} className="w-100" alt="carousel 1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Img3} className="w-100" alt="carousel 1" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home2;

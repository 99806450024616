import { React, useState } from "react";
import Logo from "../../images/Pop Authentication Services No Stroke.png";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { BaseUrl } from "../../helper/Baseurl";
import { Loader } from "react-overlay-loader";
import { useSnackbar } from "notistack";
import { useNavigate, Link } from "react-router-dom";
import bg from "../../images/Wallpaper.jpg"
const Signup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const Navigate = useNavigate();

  const SignupSchema = Yup.object().shape({
    full_name: Yup.string().required("First Name Is Required"),
    last_name: Yup.string().required("Last Name Is Required"),
    checkbox: Yup.boolean().oneOf(
      [true],
      "To continue, read and agree to the Collectors User Agreement and Privacy Policy"
    ),
    email: Yup.string()
      .email("Please Enter a Valid Email")
      .required("Email Is Required"),
    password: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Password must contain at least 8 characters"),
    password_confirmation: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Password must contain at least 8 characters")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  return (
    <>
      {loader ? <Loader fullPage loading /> : null}
      <div style={{ backgroundImage: `url(${bg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", minHeight: "100vh", backgroundPosition: "80%" }}>
        <div
          className="container-fluid"
          style={{ borderBottom: "1px solid #f4f4f4" }}
        >
          <div className="row">
            <div className="col-md-12 mt-3 mb-1 d-flex justify-content-center">
              <img
                src={Logo}
                className="w-25"
                alt="POP AUTHENTICATION SERVICES"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6 bg-white p-3">
              <p className="create">Create An Account</p>
              <p>Add your information and create a password below</p>
              <Formik
                initialValues={{
                  full_name: "",
                  last_name: "",
                  email: "",
                  password: "",
                  password_confirmation: "",
                  // checkbox: "",
                }}
                validationSchema={SignupSchema}
                onSubmit={(values) => {
                  setLoader(true);
                  console.log(values);
                  axios
                    .post(`${BaseUrl.baseUrl}signup`, values)
                    .then((res) => {
                      console.log(res);
                      if (res.data.status === true) {
                        setLoader(false);
                        Navigate(`/signin`);
                        enqueueSnackbar(res?.data?.message, {
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "success",
                          autoHideDuration: 2000,
                        });
                      } else {
                        setLoader(false);

                        enqueueSnackbar(res?.data?.message, {
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                          },
                          variant: "error",
                          autoHideDuration: 2000,
                        });
                      }
                    })
                    // same shape as initial values
                    .catch((err) => {
                      setLoader(false);
                      console.log(err);
                      enqueueSnackbar(err?.response.data?.message, {
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "right",
                        },
                        variant: "error",
                        autoHideDuration: 2000,
                      });
                    });
                }}
              >
                {({ errors, touched }) => (
                  <Form className="row">
                    <div className="form-group col-md-6">
                      <Field
                        name="full_name"
                        className="form-control"
                        placeholder="First Name"
                        type="text"
                      />
                      {errors.full_name && touched.full_name ? (
                        <div className="text-danger">{errors.full_name}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <Field
                        type="text"
                        name="last_name"
                        className="form-control"
                        placeholder="Last Name"
                      />
                      {errors.last_name && touched.last_name ? (
                        <div className="text-danger">{errors.last_name}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-12">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-12">
                      <Field
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="password"
                      />
                      {errors.password && touched.password ? (
                        <div className="text-danger">{errors.password}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-12">
                      <Field
                        type="password"
                        name="password_confirmation"
                        className="form-control"
                        placeholder="Password Confirmation"
                      />
                      {errors.password_confirmation &&
                        touched.password_confirmation ? (
                        <div className="text-danger">
                          {errors.password_confirmation}
                        </div>
                      ) : null}
                      <div
                        id="confirmPassword-hint"
                        className="mt-2 text-body2 text-neutral3"
                      >
                        Password must contain at least 8 characters, including 1
                        uppercase letter, 1 lowercase letter, 1 special character,
                        and 1 number
                      </div>
                    </div>
                    <hr />
                    <div className="form-group">
                      <Field
                        type="checkbox"
                        name="checkbox"
                        style={{ marginRight: "4px" }}
                      />
                      <span className="text-body1 text-neutral2">
                        I have read and agree to the Collectors User Agreement and
                        have read the Collectors Privacy Policy
                      </span>
                      {errors.checkbox && touched.checkbox ? (
                        <div className="text-danger">{errors.checkbox}</div>
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-end">
                      <p className="create2">
                        Already Have Account?{" "}
                        <Link
                          to="/signin"
                          className=""
                          style={{ textDecoration: "none" }}
                        >
                          Sign In
                        </Link>
                      </p>
                    </div>
                    <div className=" d-flex justify-content-center">
                      <button type="submit" className="btn btn-primary col-md-6">
                        Submit
                      </button>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <p className="create2">
                        Copyright 2023 PopCard | Design By{" "}
                        <Link
                          to="https://hnhtechsolutions"
                          className=""
                          style={{ textDecoration: "none" }}
                        >
                          Hnh Tech Solutions
                        </Link>
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
export default Signup;
